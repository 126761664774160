body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.teach {
  max-width: 100%;
  min-width: 400px;
  max-height: 100%;
  min-height: 300px;
  height:auto;
  background-image: url('./logo512.png');
  background-repeat: no-repeat;
	background-size: contain;
  /* margin-left: 50%; */
  text-align: center;
  /* margin: 0; */

}
.swm {
  background-color: white;
  /* color: green; */
  /* border: 7px solid black; */
  cursor: pointer;
  margin: 5%;
  padding: 3% 100px;
  text-align: center;
  border: #282c34 5px solid;
  /* border-radius:5%; */
  font-weight: 700;
  
  
}
.swm2 {
  background-color: white;
  /* color: green; */
  border: 2px solid black;
  cursor: pointer;
  margin: 5%;
  
  padding: 3% 100px;
  text-align: center;
  border: #282c34 5px solid;
  /* border-radius:5%; */
  font-weight: 700;
  
  
}
.color-blue {
  color:blue;
  text-decoration: wavy;
}
.color-red {
  color:red;
  text-decoration: wavy;
}
.color-green {
  color:green;
  text-decoration: wavy;
}
.color-yellow {
  color:yellow;
  text-decoration: wavy;
}
.color-purple {
  color:purple;
  text-decoration: wavy;
}
.color-orange {
  color: orange;
  text-decoration: wavy;
}
.payment {
  text-align: center;
}
.apple {
  /* max-width: 100%; */
  min-width: 150px;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./apple.ico');
  background-repeat: no-repeat;
	background-size: contain;
  /* margin-left: 50%; */
  text-align: center;
  /* margin: 0; */
  animation: App-logo-spin infinite 20s linear;

}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.eli{
  text-align: center;
  background-color: purple;
  margin-bottom: 0%;
  padding-bottom: 15%;

}
.tommy {
  text-align: center;
  background-color: black;
  color:green;
  margin-bottom: 0%;
  padding-bottom: 15%;

}
.johnny {
  text-align: center;
  background-color: rgb(187, 187, 187);
  color: black;
  margin-top: 0%;
  margin-bottom: 0%;
  padding-bottom: 15%;

}
#basement{
  padding: 20px;
}
.joey {
  text-align: center;
  background-color: grey;
  margin-bottom: 0%;
  padding-bottom: 15%;

}
.errors {
  color: red;
  font-weight: bolder;
  font-size: large;
  background-color: white;
  /* border: 2px solid blue; */
  margin-left: 35%;
  text-align: center;
}
input[type='checkbox'] {
  width: 20px;
  height: 20px;
}
input[type='checkbox'] {
  border: 2px solid #333;
}
/* Unchecked */
input[type='checkbox']:not(:checked) {
  background-color: #fff;
}

/* Checked */
input[type='checkbox']:checked {
  background-color: #007bff;
}
/* Hide default checkbox */
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Use a custom icon */

/* Style the label */
input[type='checkbox'] + label {
  cursor: pointer;
}

/* Hide the default checkbox */
input[type='checkbox'] {
  opacity: 0;
  /* position: absolute; */
}

/* Show the custom icon when the checkbox is checked */
/* input[type='checkbox']:checked + label::before {
  background-color: #007bff;
  color: blue;
} */






.custom-checkbox {
  width: 340px;
  height: 80px;
}
.custom-checkbox input#status {
  display: none;
}
.custom-checkbox input#status + label {
  height: 100%;
  width: 100%;
}
.custom-checkbox input#status + label > .status-switch {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: grey;
  color: white;
  transition: all 0.5s ease;
  padding: 3px;
  border-radius: 3px;
}
.custom-checkbox input#status + label > .status-switch:before, .custom-checkbox input#status + label > .status-switch:after {
  border-radius: 2px;
  height: calc(100% - 6px);
  width: calc(50% - 3px);
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  transition: all 0.3s ease;
}
.custom-checkbox input#status + label > .status-switch:before {
  background-color: white;
  color: black;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
  left: 3px;
  z-index: 10;
  content: attr(data-unchecked);
}
.custom-checkbox input#status + label > .status-switch:after {
  right: 0;
  content: attr(data-checked);
}
.custom-checkbox input#status:checked + label > .status-switch {
  background-color: #40c253;
}
.custom-checkbox input#status:checked + label > .status-switch:after {
  left: 0;
  content: attr(data-unchecked);
}
.custom-checkbox input#status:checked + label > .status-switch:before {
  color: #40c253;
  left: 50%;
  content: attr(data-checked);
}
#Svideo {
  background-color: rgb(190, 190, 190);
  border: 2px solid white;
  border-radius: 4px;
  display: inline-block;
  font: bold;
  font-size: medium;
  text-align: center;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; */
  /* box-sizing: border-box;
  -webkit-appearance: none; */
  /* -moz-appearance: none; */
}
#select {
  background-color: rgb(190, 190, 190);
  border: thin solid grey;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  /* -moz-appearance: none; */
}
#nono {
  background-color: rgb(255, 255, 255);
  /* border: none; */
  border-radius: 0px;
  display: auto;
  font: auto;
  line-height: auto;
  padding: auto;
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: none;
  box-sizing: none;
  -webkit-appearance: none;
  -moz-appearance: none; */
}
#select.arrows {
  background-image:    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC');
  background-position: calc(100% - .5rem), 100% 0;
  background-size:  1.5em 1.5em;
  background-repeat: no-repeat;
  text-align: center;
  
}

#select.arrows:focus {
  border-color: blue;
  outline: 0;
}





@media (max-width: 767px) {
  #basement{
    padding: 20px 0px;
  }
  .schead {
    font-size: 9px;
    font-weight: 600;
  }





}
